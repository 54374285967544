import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Helmet } from "react-helmet"

import background from "../images/ford-hero.jpg"
import Layout from "../components/Layout"
import "../components/Layout.css"
import Seo from "../components/Seo"

// styles
const Feature = styled.div`
  a {
    display: inline-block;
  }

  h1 {
    color: rgba(81, 159, 204, 1);
    transition: 3s .2s cubic-bezier(0.01, 0.8, 0.2, 1);

    @media (max-width:640px) {
      line-height: 1.1
    }
  }
`

const FeatureBoxWrapper = styled.div`
  overflow: hidden;
`

const FeatureBox = styled.div`
  background: url(${background});
  background-position: center;
  background-size: cover;
  height: 50vh;
  margin-top: 12px;
  border-radius: 18px;
  animation: BackgroundZoomOut 1s ease;
  transition: 3s .2s cubic-bezier(0.01, 0.8, 0.2, 1);
  overflow: hidden;
  ${'' /* transition: transform 1s; */}

  :hover {
    ${'' /* transform: scale(1.05); */}
    animation: BackgroundZoomIn 1s ease;
    overflow: hidden;

  }
`
const BackArrow = styled.div`
  width: 57px;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;

  :hover i, :hover p {
    color: rgba(60, 200, 215, 1);
  }  

  i {
    content: "\f007";
    font-family: 'Font Awesome 5 Free', 'Font Awesome 5 Brands';
    color: rgba(0, 0, 0, .78);
    font-style: normal;
  }

  p {
    font-family: 'Alpino-Bold', 'Arial', 'Helvetica', 'sans-serif';
    font-size: 19.2px;
    margin: 0;
  }

  @keyframes BackgroundZoomIn {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.2);
    }
  }

  @keyframes BackgroundZoomOut {
    0% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
`

const Introduction = styled.div`
  margin-top: 36px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2.5%;

  a {
    font-weight: 500;
    color: rgba(81, 159, 204, 1);
  }

  a:hover {
    color: rgba(60, 200, 215, 1);
  }

  @media (max-width:640px) {
    grid-template-columns: repeat(1, 1fr);
  }
`
const ProjectOverViewWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;

  @media (max-width:640px) {
    grid-column-start: 1;
    grid-column-end: 1;
  }
`
const ProjectOverview = styled.div`
`
const ProjectDetailsWrapper = styled.div`
  grid-column-start: 3;
  grid-column-end: 3;

  @media (max-width:640px) {
    grid-column-start: 1;
    grid-column-end: 1;
  }
`
const ProjectDetails = styled.div`
  hr {
    opacity: 0;

    @media (max-width:640px) {
      opacity: 1;
      padding-top: 24px;
    }
  }

  @media (max-width:640px) {
    margin-top: 24px;
  }
`

const ProjectDescription = styled.div`
  p {
    margin-right: 15%;

  a {
    font-weight: 500;
    color: rgba(81, 159, 204, 1);
  }

  a:hover {
    color: rgba(60, 200, 215, 1);
  }

    @media (max-width:640px) {
      margin-right: auto;
    }
  }

  img {
    width: 100%;

    @media (max-width:640px) {
      width: 100%;
    }
  }

  ul {
    list-style: none;
  }

  ul li::before {
    content: "✅";
    display: inline-block;
    margin-right: 0.2rem;
    list-style-position: outside;
  }
`

const VideoEmbed = styled.div`
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const ScrollOnUp = styled.div`
    display: block;

    :hover i, :hover p {
        color: rgba(60, 200, 215, 1);
    }
`

const ProjectWrapper = styled.div`
  max-width: 1344px;
  margin: 0 auto;
`

const ff = () => (
    <Layout>
      <Seo title="Ford Factory" />
        <Helmet>
            <script src="https://kit.fontawesome.com/077761ae24.js" crossOrigin="anonymous"></script>
        </Helmet>
        <div className="Hero">
        <div id="top"/>
        <ProjectWrapper>
            <Feature>
            <Link to="/work">
                <BackArrow>
                <i className="fas fa-long-arrow-alt-left"></i>
                <p>back</p>
                </BackArrow>
            </Link>
            <h1>Ford Factory Website</h1>
            <FeatureBoxWrapper>
                <FeatureBox />
            </FeatureBoxWrapper>
            </Feature>
            <Introduction>
            <ProjectOverViewWrapper>
                <ProjectOverview>
                <h2>Project Overview</h2>
                <h4>Located on the south end of the Downtown L.A. Arts District, the Ford Factory is a four-building 257,000 square foot brick industrial compound with heavy-duty mushroom cap columns and a rooftop water tower. From 1913 — 1929, it served as the original manufacturing assembly plant of Ford’s Model T and Model A vehicles for the Western United States.</h4>
                <p>Rich in history, the building was later occupied by U.S. Rubber, Lockheed Aircraft, Bullocks, and the Imperial Toy Company to name a few. Restoration of the building into creative office space was thoughtfully designed by <a href="https://rockefellerkempel.com/" target="_blank" rel="noreferrer">Rockefeller Kempel Architects</a> in El Segundo, and our team was brought on to help market the opportunity.</p>
                </ProjectOverview>
            </ProjectOverViewWrapper>
            <ProjectDetailsWrapper>
                <ProjectDetails className="projectDetails">
                <hr></hr>
                <h5 className="details">Credits</h5>
                <p className="details">Produced at Looking with <a href="https://www.linkedin.com/in/carterthethird/" target="_blank" rel="noreferrer">William H. Carter III</a> as dev lead, design by yours truly, <a href="https://www.linkedin.com/in/clifford-william-fong-7abb6033/">Cliff William Fong</a> Photography, and <a href="https://www.flickr.com/people/randyhoward/">Randy Howard</a> Photos</p>
                </ ProjectDetails>
                <ProjectDetails className="projectDetails">
                <h5 className="details">Client</h5>
                <p className="details"><a href="https://shorenstein.com/" target="_blank" rel="noreferrer">Shorenstein Properties</a></p>
                </ ProjectDetails>
                <ProjectDetails className="projectDetails">
                <h5 className="details">agency</h5>
                <p className="details"><a href="https://looking.la/">Looking</a></p>
                </ ProjectDetails>
                <ProjectDetails className="projectDetails">
                <h5 className="details">Deliverable</h5>
                <p className="details">Responsive Website</p>
                </ ProjectDetails>
                <ProjectDetails className="projectDetails">
                <h5 className="details">Goal</h5>
                <p className="details">Marketing collateral communications for initial marketing push</p>
                </ ProjectDetails>
                <ProjectDetails className="projectDetails">
                <h5 className="details">RESULTS</h5>
                <p className="details">After a major tenant backed-out, the building went from empty to <a href="https://labusinessjournal.com/news/2016/oct/07/warner-music-group-inks-ford-factory-lease/" target="_blank" rel="noreferrer">full single tenant lease</a> = the dream.</p>
                </ProjectDetails>
                <hr></hr>
            </ProjectDetailsWrapper>
            </Introduction>
            <ProjectDescription className="projectChapter">
            <h2>Discovery</h2>
            <h3>Design Inspiration</h3>
            <p>Starting with a creative brief, three rough renderings, and a new identity to the project’s name, the design process started with developing a visual language as unique as the building. For example, a color palette was built from Ford’s Classic Shelby Mustang, <a href="https://www.shelby.com/en-us/" target="_blank" rel="noreferrer">Shelby</a> itself a Ford performance variant also built in Los Angeles in the 1960’s.</p>
            <StaticImage
                src="../images/ford-color-study.jpg"
                alt="Color study of Ford inspiration"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="blurred"
            />
            <div className="imgcaption">Color study inspired by various historical Ford palettes</div>
            <p>While clarity was stressed, visual details were treated with nostalgia, care was given down to type choice. Old Model T car manuals were studied and typography was built around characters that were relevant during the factory’s operation.</p>
            <StaticImage
                src="../images/ford-history1936.jpg"
                alt="Pic from the Ford Factory showing giant mushroom cap columns"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="blurred"
            />
            <div className="imgcaption">Image from the website showing just how dense and heavy this building was, 300lbs./sq. ft. is a tank.</div>
            <h3>Interviews & Insights</h3>
            <p>Observations from the architect were incorporated into the website and eventual marketing book. This is a building that is super heavy, just look at all of the sand bags above. The architects in their design, created a dramatically open and light space, so we conceptually played with the contrast of light and heavy…whitespace with very hueful imagery of Downtown Los Angeles.</p>
            <p>In conducting interviews with the client, a big misnomer in the commercial real estate was the definition of the Arts District. For a broker in New York, what was the Downtown L.A. Arts District? What was happening to the region? How would a tenant make use of working there, and what were current conditions like?</p>
            </ProjectDescription>
            <ProjectDescription className="projectChapter">
            <h2>Observations & Hypothesis</h2>
            <h3>Driving Home the DTLA Arts District</h3>
            <p>With a main demographic being the commercial real estate brokerage community, the demo below shows how we served an education on the entire Downtown L.A. Arts District in about 30 seconds. Short attention spans rejoice!</p>
            <VideoEmbed className="projectDemo">
                <iframe title="website demo" src="https://www.youtube.com/embed/DBmoByWfSuY?rel=0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </VideoEmbed>
            <p>During information architecture stages, we explored an entire page dedicated to informing users of the location. Here is what we ended up with, a success from client feedback.</p>
            <ul>
                <li> Color-coded map showcasing the different “neighborhoods” of Downtown Los Angeles.</li>
                <li> Brilliant current lifestyle photography taken by the talented and ballsy Cliff Fong.</li>
                <li> An ever-evolving dynamic map of amenities in the area by vertical type. There was generous feedback from the client here.</li>
                <li> A static transportation map orienting users, including highlighting public transportation to the location.</li>
            </ul>
            
            </ProjectDescription>
            <ProjectDescription className="projectChapter">
            <h2>Iterations</h2>
            <p>Initially launched in 2015, the website continually housed content updates to amenities and renderings. Outside of content, the design remained stable through full lease the following year. It is amazing how many restaurants and boutique shops opened in the area during the window of a year. The design remained stable throughout the campaign, and led to the eventual design and publishing of a nice marketing book.</p>
            </ProjectDescription>
            <ProjectDescription className="projectChapter">
            <h2>Terminus</h2>
            <p>In October of 2016, Warner Music Group signed a multi-year lease effectively completing marketing efforts. Congrats to Shorenstein, CBRE, as well as the Downtown L.A. Arts District which is well deserving of a big name in town</p>
            </ProjectDescription>
            <ScrollOnUp 
            onClick={() => scrollTo('#top')}>
            <div className="ScrollToTop">
                <p>Scroll to top</p>
                <i className="fas fa-angle-up"></i>
            </div>
            </ScrollOnUp>
        </ProjectWrapper>
        </div>
    </Layout>
  )

export default ff